import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrderTypeHeader from "../OrderTypeHeader";
import { useState } from "react";
import {
  addAppoiuntment,
  cancelAppoiuntment,
  completeAppoiuntment,
  createAppoiuntment,
  getDataByID,
  getOrderItemCoaching,
  updateAssignedTo,
} from "../../../../../services/api";
import ActivityTracker from "../components/ActivityTracker/ActivityTracker";
import AppointmentDropdown from "../components/AppointmentDropdown/AppointmentDropdown";
import { svg } from "../../../../../services/svg";
import { useSelector } from "react-redux";
import CustDropdown from "../../../../../components/CustInput/CustDropdown";
import { getCalendlyUrl } from "../../../../../utils/utils";
import Results from "../../components/Results";
import IntakeView from "../../../../../components/Intake/IntakeView/IntakeView";

const Consult = ({ is_bundle }) => {
  const {
    user: { token },
    employee = [],
  } = useSelector((state) => state.appState);
  const { orderid, bundleid, consultid, page, subnav } = useParams();

  const store = useSelector((state) => state.appState);
  const [current_open_dropdown, setcurrent_open_dropdown] = useState("");

  useEffect(() => {
    if (page) {
      setnav(page);
    } else {
      if (!["counselor", "doctor"].includes(store?.user?.type?.toLowerCase())) {
        navigate("./overview/activity", { replace: true });
      } else {
        navigate("./results/activity", { replace: true });
      }
    }
  }, [page]);
  const dropdown_settings = {
    is_open: current_open_dropdown,
    setdropdown: setcurrent_open_dropdown,
  };
  const [intakeold, setintakeold] = useState({});
  const [intake, setintake] = useState({});
  const navigate = useNavigate();
  const [nav, setnav] = useState("Overview");
  // const [nav, setnav] = useState("Results");
  const [activity, setactivity] = useState([]);
  const [appointments, setappointments] = useState([]);
  const [orderData, setorderData] = useState([
    {
      orderId: "",
      order: {
        _id: "",
        orderId: "",
        lab: {
          name: "",
          orderId: "",
          technician: {
            name: "",
            phone: "",
          },
          report: "",
          result: {},
          activity: {
            type: "",
            by: {
              name: "",
            },
            dateTime: "",
          },
        },
      },
      customer: {
        name: "",
        phone: "",
        address: {
          pincode: "",
          address: "",
          apt: "",
          landmark: "",
          city: "",
        },
      },
      product: {
        name: "",
        type: "",
        sku: "",
        goTo: "",
        bundle: {
          name: "",
          goTo: "",
        },
      },
      status: "",
      assignedTo: "",
      priority: "",
      dates: {
        order: "",
        appointment: "",
      },
      amount: {
        gross: 5160,
        discount: {
          details: {
            name: "",
          },
        },
      },
      invoice: {
        invoiceId: "",
        goTo: "",
      },
    },
  ]);
  const [results_obj, setresults_obj] = useState({
    results: [],
    current_order: {},
    userData: {},
    testid: "",
  });
  const [reports_obj, setreports_obj] = useState({});

  useEffect(() => {
    getDataByID(
      token,
      consultid,
      "consult",
      (orderItem) => {
        if (!orderData) {
          return;
        }
        //
        if (orderItem) {
          setorderData(
            [orderItem.item].map((el) => {
              setappointments(el.appointment);
              const {
                order,
                product_status,
                product_priority,
                dates,
                assigned_to,
                product_price,
                product_name,
                product_type,
                product_id,
                bundle_name,
                user,
                _id,
                discount_code,
              } = el;

              return {
                orderId: order?.orderId || "",
                order: {
                  _id: order?._id || "",
                  orderId: order?.orderId || "",
                  lab: {
                    name: "",
                    orderId: "",
                    technician: {
                      name: "",
                      phone: "",
                    },
                    report: "/report/report_id",
                    result: {},
                    activity: {
                      type: "status change",
                      by: {
                        name: "System",
                      },
                      dateTime: "7:30 pm",
                    },
                  },
                },
                customer: {
                  name:
                    [
                      user?.user?.first_name || "",
                      user?.user?.last_name || "",
                    ].join(" ") || "",
                  phone: user.user.phone,
                  email: user.user.email,
                  goTo: "dashboard/customers/" + user?.user_id + "/overview",
                  address: {
                    ...user.address,
                  },
                },
                product: {
                  name: product_name,
                  type: product_type,
                  sku: product_id,
                  goTo:
                    product_type == "guide"
                      ? ""
                      : "/" + product_type + "/" + _id,
                  bundle: {
                    name: orderItem?.bundle?.name || "",
                    goTo: "/bundle/" + orderItem?.bundle?.bundleId || "",
                  },
                },
                status: product_status,
                assignedTo: assigned_to,
                priority: product_priority,
                dates: {
                  ...dates,
                },
                amount: {
                  // gross: product_price,
                  // discount: {
                  //   details: {
                  //     name: discount_code,
                  //   },
                  // },
                  ...orderItem?.amounts,
                },
                invoice: {
                  invoiceId: order?.orderId ? "AH" + order.orderId : "",
                  goTo:
                    "/invoice/" + order?.orderId ? "AH" + order.orderId : "",
                },
                id: el._id,
              };
            })
          );
          // setresults_obj({
          //   results: orderItem.reports,
          //   current_order: orderItem.item,
          //   userData: orderItem.userData,
          //   testid: "",
          // });
          setreports_obj({
            reports: [...(orderItem?.reports || [])],
            activity: {
              show: false,
              data: [],
            },
            intake: {
              ...(orderItem?.userData?.intake || {}),
            },
          });
          setintake({ ...(orderItem?.userData?.intake || {}) });
          setintakeold({ ...(orderItem?.userData?.intake || {}) });
          setactivity(orderItem?.activity || []);
        }
      },
      () => {}
    );
  }, []);
  return (
    <div className=" dashboard_page">
      <OrderTypeHeader
        {...{
          breadCrumbs: [
            {
              name: orderData[0]?.order?.orderId
                ? "#" + orderData[0]?.order?.orderId
                : "NA",
            },
            is_bundle
              ? {
                  name: orderData[0]?.product?.bundle?.name || "NA",
                  goto: orderData[0]?.product?.bundle?.goTo
                    ? "../../../orders/" +
                      orderData[0]?.order?.orderId +
                      orderData[0]?.product?.bundle?.goTo
                    : "",
                }
              : null,
            {
              name: orderData[0]?.product?.name || "NA",
              // goto: orderData[0]?.product?.goTo || "",
            },
          ].filter((el) => el),
          orderDate: orderData[0]?.dates?.order || "",
          customer: {
            name: orderData[0]?.customer?.name || "",
            goTo: orderData[0]?.customer?.goTo || "",
            phone: orderData[0]?.customer?.phone || "",
            email: orderData[0]?.customer?.email || "",
          },
          nav_list: ["Overview", "Results", "Intake"],
          nav,
          setnav,
        }}
      />
      <div className="appointment_body dashboard_body">
        {nav.toLowerCase() == "overview" &&
          !["counselor", "doctor"].includes(
            store?.user?.type?.toLowerCase()
          ) && (
            <>
              <div className="body_left">
                {!["counselor", "doctor"].includes(
                  store?.user?.type?.toLowerCase()
                ) ? (
                  <div className="summary">
                    <div className="amount_main_group">
                      <div className="status_group">
                        <div className="title">Status</div>
                        <div
                          className="order_status chips"
                          {...{
                            color: {
                              High: 2,
                              Medium: 4,
                              Low: "",
                            }[orderData[0]?.priority || ""],
                          }}
                        >
                          {orderData[0]?.status || (
                            <span className="na">N/A</span>
                          )}
                        </div>
                      </div>
                      {!["counselor", "doctor"].includes(
                        store?.user?.type?.toLowerCase()
                      ) ? (
                        <div className="assignedto_group">
                          <div className="title">Assigned to</div>
                          <div className="body">
                            {orderData[0]?.assignedTo ? (
                              <CustDropdown
                                {...{
                                  ...dropdown_settings,
                                  dropdown_align: "left_align",
                                  multiselect: false,
                                  title: orderData[0]?.assignedTo,
                                  value: orderData[0]?.assignedTo,
                                  setvalue: (value) => {
                                    if (value[0]) {
                                      // navigate(value[0]);
                                      updateAssignedTo(
                                        token,
                                        value[0],
                                        "/test/" + consultid,
                                        (success) => {
                                          if (success?.success) {
                                            window.location.reload();
                                          }
                                        },
                                        () => {}
                                      );
                                    }
                                  },
                                  list: [
                                    ...employee?.map((el) => ({
                                      key: el,
                                      value: el,
                                    })),
                                  ],
                                  list_show: (data) => data.value,
                                }}
                              />
                            ) : (
                              <span className="na">N/A</span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="amount_main_group">
                      <div className="amount_group">
                        <div className="title">MRP</div>
                        <div className="body">
                          {orderData[0]?.amount?.net || (
                            <span className="na">N/A</span>
                          )}
                        </div>
                      </div>
                      {orderData[0]?.amount?.net !=
                        orderData[0]?.amount?.amount && (
                        <>
                          <div className="amount_group">
                            <div className="title">Amount</div>
                            <div className="body">
                              {orderData[0]?.amount?.gross || (
                                <span className="na">N/A</span>
                              )}
                            </div>
                          </div>
                          <div className="discount_group">
                            <div className="title">Discount</div>
                            <div className="body">
                              {orderData[0]?.amount?.discount?.details
                                ?.name || <span className="na">N/A</span>}
                            </div>
                          </div>
                        </>
                      )}
                      {/* <div
                    className="invoice_group"
                    onClick={() => window.open(orderData[0]?.invoice?.goTo)}
                  >
                    <div className="title">Invoice</div>
                    <div className="body">
                      {orderData[0]?.invoice?.invoiceId
                        ? orderData[0]?.invoice?.invoiceId
                        : "-"}
                      {svg.chain}
                    </div>
                  </div> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="lab_summary">
                  <div className="title">
                    Appointment
                    <div className="goto">
                      Go to booking page {svg.chevron.right}
                    </div>
                  </div>

                  {console.log("test", orderData[0])}
                  <AppointmentDropdown
                    appointments={appointments}
                    size="1"
                    link={
                      getCalendlyUrl(
                        (orderData[0]?.amount?.discount?.details?.name || "") +
                          "_" +
                          (orderData[0]?.product?.sku || ""),
                        results_obj?.userData?.address?.state,
                        results_obj?.current_order?.product_id
                      ).link || ""
                    }
                    schedule={(eventData) => {}}
                    cancel={(eventData) => {}}
                    complete={(eventData) => {}}
                    order_id={orderData[0].id}
                    addAppoiuntment={(id, evenetData) =>
                      addAppoiuntment(
                        token,
                        id,
                        evenetData,
                        (success) => {
                          window.location.reload();
                        },
                        (err) => {}
                      )
                    }
                    cancelAppoiuntment={(id) =>
                      cancelAppoiuntment(
                        token,
                        id,
                        (success) => {
                          window.location.reload();
                        },
                        (err) => {}
                      )
                    }
                    createAppoiuntment={(order_id) =>
                      createAppoiuntment(
                        token,
                        order_id,
                        (success) => {
                          window.location.reload();
                        },
                        (err) => {}
                      )
                    }
                    completeAppoiuntment={(id) =>
                      completeAppoiuntment(
                        token,
                        id,
                        (success) => {
                          window.location.reload();
                        },
                        (err) => {}
                      )
                    }
                  />
                </div>
              </div>
              <div className="body_right">
                <ActivityTracker
                  data={[...activity]}
                  order_id={orderData[0].id}
                  token={token}
                  current_page="consult"
                />
              </div>
            </>
          )}
        {nav == "Results" && <Results {...{ ...reports_obj }} />}
        {nav == "Intake" && (
          <div className="invoice_main">
            <IntakeView
              type={"view"}
              to_update={intakeold ? { ...intakeold } : {}}
              data={
                intake
                  ? {
                      ...intake,
                    }
                  : {}
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Consult;
