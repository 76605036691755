import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustDropdown from "../../../../components/CustInput/CustDropdown";
import CustSearch from "../../../../components/CustInput/CustSearch";
import CustTable from "../../../../components/Table/CustTable";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../../../services/api";
import { formatPhone } from "../../../../utils/utils";

const Customers = () => {
  const {
    user: { token },
    products,
    allcustomers,
  } = useSelector((state) => state.appState);
  const table_div = useRef();

  const [order_search, setorder_search] = useState("");
  const [usersToShow, setusersToShow] = useState([]);
  const [users, setusers] = useState([
    // {
    //   customerName: "Alice",
    //   phone: "1234567890",
    //   email: "alice@example.com",
    //   date: "05/30/2024",
    //   goal: "Learn JavaScript",
    // },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (allcustomers) {
      setusers(allcustomers);
    }
    getCustomers(
      token,
      (success) => {
        if (success) {
          //
          let users = success.map(
            ({
              _id,
              first_name,
              last_name,
              prefix,
              phone,
              email,
              goal,
              lastOrderDate,
            }) => ({
              customerName: `${first_name} ${last_name}`,
              phone: formatPhone(phone, prefix),
              unformatPhone: phone,
              email,
              date: lastOrderDate,
              goal,
              id: _id,
            })
          );
          setusers(users);
          dispatch({
            type: "setAppState",
            subType: "allcustomers",
            payload: [...users],
          });
        }
      },
      (err) => {}
    );

    return () => {};
  }, []);
  const [pagination, setpagination] = useState({
    start: 0,
    diff: 100,
  });
  useEffect(() => {
    let filteredUsers = users
      ?.filter(
        (el) =>
          el.customerName
            .toLowerCase()
            .includes(order_search.trim().toLowerCase()) ||
          el.unformatPhone.match(order_search.replaceAll(" ", "").trim())
      )
      .map((el, i) => ({
        ...el,
        customerName: i + 1 + " - " + el.customerName,
      }));
    setpagination((prev) => ({
      ...prev,
      start: 0,
    }));
    setusersToShow(filteredUsers);
  }, [users, order_search]);
  useLayoutEffect(() => {
    setpagination({
      start: 0,
      diff: Math.floor(
        (table_div.current.getBoundingClientRect().height - 55) / 57
      ),
    });
  }, []);

  return (
    <div className="dashboard_body customer_page orders_page">
      <div className="dashboard_subheader">
        <div className="title">Customers</div>

        <div className="search_and_filter">
          <CustSearch
            {...{
              value: order_search,
              setvalue: setorder_search,
            }}
          />
        </div>
      </div>
      <div className="dashboard_subbody">
        <div className="customer_table" ref={table_div}>
          <CustTable
            {...{
              data: usersToShow
                .slice(pagination.start, pagination.start + pagination.diff)
                .map((el, i) => ({
                  ...el,

                  rowClick: () => navigate(`../${el.id}/overview`),
                })),
              nosrnogap: true,
              header: [
                {
                  id: "customerName",
                  title: "Customer",
                },
                {
                  id: "phone",
                  title: "Phone Number",
                },
                {
                  id: "email",
                  title: "Email",
                },
                {
                  id: "date",
                  title: "Last Order Date",
                },
                {
                  id: "goal",
                  title: "Goal",
                },
              ],
              onrowclick: (row_data) => {},
            }}
          />
        </div>
        <div className="pagination_customer">
          <div
            className="prev"
            onClick={() => {
              setpagination((prev) => ({
                ...prev,
                start: prev.start - prev.diff <= 0 ? 0 : prev.start - prev.diff,
              }));
            }}
          >
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="var(--svg_color,#5F1D1B)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <div>
            {pagination.start + 1} -{" "}
            {pagination.start + pagination.diff > usersToShow.length
              ? usersToShow.length
              : pagination.start + pagination.diff}
          </div>
          <div
            className="next"
            onClick={() => {
              setpagination((prev) => ({
                ...prev,
                start:
                  prev.start + prev.diff >= usersToShow.length
                    ? prev.start
                    : prev.start + prev.diff,
              }));
            }}
          >
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="var(--svg_color,#5F1D1B)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
